@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

.custom-select {
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  padding: 8px 2px;
  padding-left: 20px;
  width: 190px;
}